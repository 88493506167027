import { concatStyleSets, IStyleSet, mergeStyles } from '@fluentui/merge-styles';
import { Theme, createTheme, ComponentsStyles, initializeIcons, PartialTheme } from '@fluentui/react';
import { BVComponentName } from '../models';
/**
 *
 * @param name Component name to get the styles of a component from Global theme
 * @param themes Theme names to get the styles from componentThemeStyles
 * @param componentThemeStyles styles of Themes
 * @param globalTheme The global theme from ThemeProvider
 * @returns
 */
export const getThemeStyles = <S>(name: string, themes: string[] = ['default'], componentThemeStyles: ({ [key: string]: IStyleSet<S> } | undefined)[], globalTheme: Theme): IStyleSet<S> => {
  const globalComponentTheme = globalTheme.components ? globalTheme.components[name] : null;
  const overrideComponentTheme = globalTheme.components ? globalTheme.components[BVComponentName.Override_ + name] : null;
  const themeStyles: IStyleSet<S>[] = [];
  // theme order: global < component < override styles.
  if (globalComponentTheme && globalComponentTheme.styles) {
    themeStyles.push(globalComponentTheme.styles);
  }
  // Dark or White labeling
  if (overrideComponentTheme && overrideComponentTheme.styles) {
    componentThemeStyles.push(overrideComponentTheme.styles as { [key: string]: IStyleSet<S> });
  }
  // an addtional custom default styles can be added.
  if (!themes.includes('default')) {
    themes.unshift('default');
  }
  themes.forEach((theme) => {
    componentThemeStyles.forEach((styles, i) => {
      if (styles && styles[theme]) {
        // the first default is provided by Global Theme.
        if (i !== 0 || (i === 0 && theme !== 'default')) {
          themeStyles.push(styles[theme]);
        }
      }
    });
  });
  return concatStyleSets(...themeStyles) as IStyleSet<S>;
};

/**
 * @param name
 * @param themes
 * @param componentThemeStyles
 */
export const getThemeClassNames = <S, C>(name: string, themes: string[], componentThemeStyles: ({ [key: string]: IStyleSet<S> } | undefined)[], globalTheme: Theme): C => {
  const themeStyles = getThemeStyles(name, themes, componentThemeStyles, globalTheme) as { [key: string]: any };
  return Object.keys(themeStyles).reduce((classNames, key) => {
    classNames[key] = `${name}-${key} ${mergeStyles(themeStyles[key])}`;
    return classNames;
  }, {} as { [key: string]: any }) as C;
};

/**
 * Generate Global Theme for Fluent Theme Provider
 * @param baseGlobalTheme base global theme{ defaultFontStyle: {...}, semanticColors: {...}}
 * @param baseComponentsThemeStyles default global component theme
 * @param overideComponentsThemeStyles theme styles of components to override components theme styles
 */
export const getGlobalTheme = (baseGlobalTheme: PartialTheme, baseComponentsThemeStyles: { [key: string]: any }, overideComponentsThemeStyles?: { [key: string]: any }): Theme => {
  const components: ComponentsStyles = {};
  Object.keys(baseComponentsThemeStyles).forEach((key) => {
    components[key] = { styles: baseComponentsThemeStyles[key] };
  });
  if (overideComponentsThemeStyles) {
    Object.keys(overideComponentsThemeStyles).forEach((key) => {
      components[BVComponentName.Override_ + key] = { styles: overideComponentsThemeStyles[key] };
    });
  }
  initializeIcons();
  return createTheme({
    ...baseGlobalTheme,
    components,
  });
};

/**
 * return components default styles
 * @returns
 */
export const getComponentsDefaultStyles = () => {
  return {
    // Fluent Components
    // H2O.ai components. They can have `BV_` prefix if Fluent has the same component name
  };
};
