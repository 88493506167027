import { IStyle, IStyleSet, ThemeProvider, FontWeights, FontSizes, INavLinkGroup, INavLink, Nav } from '@fluentui/react';
import { ComponentName } from './shared/models';
import { Colors, DefaultTheme, Sizes, Theme, ZIndices } from './shared/styles';
import { getComponentsDefaultStyles, getGlobalTheme, getThemeClassNames } from './shared/utils/utils';
import logo from './assets/images/logo.svg';
interface IAppClassNames {
  sidebar: string;
  sidebarContent: string;
  content: string;
  header: string;
  body: string;
  footer: string;
  sidebarHeader: string;
}

interface IAppStyles {
  sidebar: IStyle;
  content: IStyle;
  header: IStyle;
  body: IStyle;
  footer: IStyle;
  sidebarHeader: IStyle;
  sidebarContent: IStyle;
}

const BodyStyle = {
  width: '100%',
  height: '100vh',
  backgroundColor: Colors.bodyBg,
  color: Colors.bodyFont,
};

const AppThemeStyles: { [key: string]: IStyleSet<IAppStyles> } = {
  app: {
    sidebar: {
      width: Sizes.sideBarWidth,
      zIndex: ZIndices.sidebar,
      position: 'fixed',
      top: 0,
      left: 0,
      bottom: 0,
      boxShadow: Colors.boxShadow,
      backgroundColor: Colors.bgContent,
    },
    content: {
      position: 'relative',
      paddingLeft: Sizes.sideBarWidth,
      paddingTop: Sizes.headerHeight,
    },
    header: {
      height: Sizes.headerHeight,
      backgroundColor: Colors.bgContent,
      boxShadow: Colors.boxShadow,
      position: 'fixed',
      width: '100%',
      top: 0,
    },
    body: {},
    footer: {},
    sidebarHeader: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: Sizes.headerHeight,
      backgroundColor: Colors.bgContentSecondary,
      fontWeight: FontWeights.semibold,
      fontSize: FontSizes.large,
    },
    sidebarContent: {},
  },
};

const menus: INavLinkGroup[] = [
  {
    links: [
      {
        name: 'Boards',
        url: '',
        expandAriaLabel: 'Expand Boards section',
        collapseAriaLabel: 'Collapse Boards section',
        links: [
          {
            name: 'My Boards',
            url: '',
            key: 'MyBoards',
            target: '_blank',
          },
          {
            name: 'Boards',
            url: '',
            key: 'Boards',
            target: '_blank',
          },
        ],
        isExpanded: true,
      },
      {
        name: 'Apps',
        url: '',
        expandAriaLabel: 'Expand Apps section',
        collapseAriaLabel: 'Collapse Apps section',
        links: [
          {
            name: 'My Apps',
            url: '',
            key: 'MyApps',
            target: '_blank',
          },
          {
            name: 'Apps',
            url: '',
            key: 'Apps',
            target: '_blank',
          },
          {
            name: 'Widgets',
            url: '',
            key: 'Widgets',
            target: '_blank',
          },
        ],
        isExpanded: true,
      },
    ],
  },
];

function App() {
  const onMenuClick = (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {};
  const globalTheme = getGlobalTheme(DefaultTheme, getComponentsDefaultStyles());
  const classNames = getThemeClassNames<IAppStyles, IAppClassNames>(ComponentName.App, [Theme.app], [AppThemeStyles], globalTheme);
  return (
    <ThemeProvider theme={globalTheme} style={BodyStyle}>
      <div className={classNames.sidebar}>
        <div className={classNames.sidebarHeader}>
          <img src={logo} alt="BoardVerse logo" height="30px" />
          &nbsp;BoardVerse
        </div>
        <div className={classNames.sidebarContent}>
          <Nav
            onLinkClick={onMenuClick}
            styles={{
              root: {
                width: '100%',
              },
              linkText: {
                color: Colors.bodyFont,
              },
              chevronIcon: {
                color: Colors.bodyFont,
              },
            }}
            ariaLabel="Nav basic example"
            groups={menus}
          />
        </div>
      </div>
      <div className={classNames.content}>
        <div className={classNames.header}>header</div>
        <div className={classNames.body}>body</div>
        <div className={classNames.footer}>footer</div>
      </div>
    </ThemeProvider>
  );
}

export default App;
