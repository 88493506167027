// When Fluent already has the same component name, we can add `BV_` prefix to a component.
// `Override_` prefix to override styles of a component. This styles will be applied as the last styles of a component. eg) Override_Button
export enum BVComponentName {
  Override_ = 'Override_',
  BV_List = 'BV_List',
  App = 'App',
}

// List from https://developer.microsoft.com/en-us/fluentui#/controls/web.
// Some child components are not in the list. https://github.com/microsoft/fluentui/tree/master/packages/react/src/components
export enum FluentComponentName {
  ActivityItem = 'ActivityItem',
  Announced = 'Announced',
  Breadcrumb = 'Breadcrumb',
  Button = 'Button',
  Calendar = 'Calendar',
  Callout = 'Callout',
  Checkbox = 'Checkbox',
  ChoiceGroup = 'ChoiceGroup',
  Coachmark = 'Coachmark',
  ColorPicker = 'ColorPicker',
  ComboBox = 'ComboBox',
  CommandBar = 'CommandBar',
  ContextualMenu = 'ContextualMenu',
  DatePicker = 'DatePicker',
  DetailsList = 'DetailsList',
  DetailsRow = 'DetailsRow',
  Dialog = 'Dialog',
  DialogContent = 'DialogContent',
  DocumentCard = 'DocumentCard',
  Dropdown = 'Dropdown',
  Facepile = 'Facepile',
  FocusTrapZone = 'FocusTrapZone',
  FocusZone = 'FocusZone',
  GroupedList = 'GroupedList',
  HoverCard = 'HoverCard',
  Icon = 'Icon',
  Image = 'Image',
  Keytips = 'Keytips',
  Label = 'Label',
  Layer = 'Layer',
  Link = 'Link',
  List = 'List',
  MarqueeSelection = 'MarqueeSelection',
  MessageBar = 'MessageBar',
  Menu = 'Menu',
  Modal = 'Modal',
  Nav = 'Nav',
  OverflowSet = 'OverflowSet',
  Overlay = 'Overlay',
  Panel = 'Panel',
  PeoplePicker = 'PeoplePicker',
  Persona = 'Persona',
  Pickers = 'Pickers',
  Pivot = 'Pivot',
  PivotItem = 'PivotItem',
  ProgressIndicator = 'ProgressIndicator',
  Rating = 'Rating',
  ResizeGroup = 'ResizeGroup',
  ScrollablePane = 'ScrollablePane',
  SearchBox = 'SearchBox',
  Selection = 'Selection',
  Separator = 'Separator',
  Shimmer = 'Shimmer',
  Slider = 'Slider',
  SpinButton = 'SpinButton',
  Spinner = 'Spinner',
  Stack = 'Stack',
  SwatchColorPicker = 'SwatchColorPicker',
  TeachingBubble = 'TeachingBubble',
  Text = 'Text',
  TextField = 'TextField',
  ThemeProvider = 'ThemeProvider',
  Themes = 'Themes',
  Toggle = 'Toggle',
  Tooltip = 'Tooltip',
  TooltipHost = 'TooltipHost',
}

export const ComponentName = { ...BVComponentName, ...FluentComponentName };

export enum KeyboardKey {
  Down = 'Down', // IE
  ArrowDown = 'ArrowDown',
  Up = 'Up', // IE
  ArrowUp = 'ArrowUp',
  Left = 'Left', // IE
  ArrowLeft = 'ArrowLeft',
  Right = 'Right', // IE
  ArrowRight = 'ArrowRight',
  Enter = 'Enter',
  Esc = 'Esc', // IE
  Escape = 'Escape',
  Tab = 'Tab',
}

export interface IComponent<S> {
  themes?: string[];
  themeStyles?: { [key: string]: S };
  dataAttributes?: { [key: string]: string };
}
