import { ICalloutContentStyles, ITooltipHostStyles, PartialTheme } from '@fluentui/react';

// *************** units *****************
export enum Colors {
  primary = '#857dcc',
  secondary = '#a7a7a7',
  success = '#519668',
  info = '#5299e0',
  warning = '#d5a439',
  danger = '#c77171',
  light = 'rgba(255, 255, 255, 0.1)',
  dark = 'rgba(0, 0, 21, 0.1)',
  primaryStart = '#4638c2',
  primaryStop = '#2d2587',
  secondaryStart = '#d1d2d3',
  secondaryStop = 'white',
  successStart = '#45a164',
  successStop = '#2e8c47',
  infoStart = '#4799eb',
  infoStop = '#4280b4',
  warningStart = '#e1a82d',
  warningStop = '#dd9124',
  dangerStart = '#d16767',
  dangerstop = '#c14f4f',
  lightStart = '#e8e8e8',
  lightstop = 'white',
  darkStart = '#4c4f54',
  darkStop = '#292a2b',
  bodyBg = '#181924',
  bodyFont = 'rgba(255, 255, 255, 0.87)',
  elevationBase = '0,0,0',
  highEmphasis = 'rgba(255, 255, 255, 0.87)',
  mediumEmphasis = 'rgba(255, 255, 255, 0.6)',
  disabled = 'rgba(255, 255, 255, 0.38)',
  border = 'rgba(255, 255, 255, 0.075)',
  link = '#948dd2',
  linkHover = '#a9a4db',
  bgPrimary = '#857dcc',
  bgSecondary = '#a7a7a7',
  bgSuccess = '#519668',
  bgInfo = '#5299e0',
  bgWarning = '#d5a439',
  bgDanger = '#c77171',
  bgLight = 'rgba(255, 255, 255, 0.1)',
  bgDark = 'rgba(0, 0, 21, 0.1)',
  bgContent = `#2a2b36`,
  bgContentSecondary = 'rgba(0, 0, 21, 0.2)',
  textPrimary = '#857dcc',
  textSecondary = '#a7a7a7',
  textSuccess = '#519668',
  textInfo = '#5299e0',
  textWarning = '#d5a439',
  textDanger = '#c77171',
  textLight = 'rgba(255, 255, 255, 0.1)',
  textDark = 'rgba(0, 0, 21, 0.1)',
  boxShadow = `0 2px 2px 0 rgba(0,0,0,.14),0 3px 1px -2px rgba(0,0,0,.12),0 1px 5px 0 rgba(0,0,0,.20)`,
}
export enum Sizes {
  listHeight = 50,
  borderRadius = 4,
  sideBarWidth = 256,
  headerHeight = 64,
}

export enum ZIndices {
  sidebar = 999,
}

// ****************** fluent component themes ****************
export const TooltipThemeStyles: { [key: string]: Partial<ITooltipHostStyles> } = {
  default: {
    root: {
      cursor: 'pointer',
    },
  },
};

// Combobox and Dropdown Callout styles need to be changed by IComboBoxStyles and IDropdownStyles. The default theme doesn't work
export const CalloutThemeStyles: { [key: string]: Partial<ICalloutContentStyles> } = {
  default: {
    root: {},
  },
};

/* ************* Global Theme ****************
UI element can have multiple themes like <Button themes={[Theme.userMenu, Theme.dark]} />
*/
export enum Theme {
  default = 'default',
  app = 'app',
}

export const DefaultTheme: PartialTheme = {
  semanticColors: {
    bodyBackground: Colors.bodyBg,
    bodyFrameBackground: Colors.bodyBg,
  },
};

// This needs to be applied colors for Dark Theme. Added a black for testing Dark Theme
export const DarkTheme: PartialTheme = {};
